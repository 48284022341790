import React from 'react';
import {View, Image, StyleSheet, ScrollView} from 'react-native';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
// import ChatRoomSvg from '@/components/svgs/basic/chatroom';
import {toPriceStr} from '@/utils';
import {useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';
import LazyImage from '@/components/basic/image';
import {getSideList} from '@/pages/home/home.service';
import {postReport, TReportType, TSourceType} from '@/services/global.service';
import {TGameType} from '@/services/global.service';
const closeIcon = require('@assets/icons/close.webp');

const SharePanel = ({
  hasReward = false,
  onItemPress = () => {},
  onClose = () => {},
  gameType,
}: {
  onItemPress: (platform: string) => void;
  onClose: () => void;
  hasReward: boolean;
  gameType: TGameType;
}) => {
  const {i18n} = useTranslation();
  const [imIcon, setImIcon] = React.useState<string | undefined>(
    globalStore.imIcon,
  );
  const imShare = {
    label: 'Chatroom',
    icon: () => (
      // <ChatRoomSvg width={theme.imageSize.s} height={theme.imageSize.s} />
      // globalStore.imIcon
      <View
        style={[
          {
            width: 48,
            height: 48,
            borderRadius: 24,
            backgroundColor: '#fff',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 3,
            borderColor: theme.basicColor.primary,
          },
        ]}>
        <LazyImage
          // @ts-ignore
          imageUrl={imIcon}
          width={48}
          height={48}
          occupancy="transparent"
        />
      </View>
    ),
  };
  const thirdShares = [
    {
      label: 'Facebook',
      icon: () => (
        <Image
          style={[styles.itemIcon]}
          source={require('@components/assets/icons/facebook.webp')}
        />
      ),
    },
    {
      label: 'Telegram',
      icon: () => (
        <Image
          style={[styles.itemIcon]}
          source={require('@components/assets/icons/tg.webp')}
        />
      ),
    },
    {
      label: 'Whatsapp',
      icon: () => (
        <Image
          style={[styles.itemIcon]}
          source={require('@components/assets/icons/whatsapp.webp')}
        />
      ),
    },
    {
      label: 'Instagram',
      icon: () => (
        <Image
          style={[styles.itemIcon]}
          source={require('@components/assets/icons/insgram.webp')}
        />
      ),
    },
  ];

  const getIcon = async () => {
    if (imIcon) {
      return;
    }
    try {
      const res = await getSideList();
      if (res && res.length) {
        for (const item of res) {
          if (item.moduleType === 'IM_APP') {
            globalStore.imIcon = item.defaultImgUrl;
            setImIcon(item.defaultImgUrl);
            return;
          }
        }
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  React.useEffect(() => {
    getIcon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={[theme.background.white, styles.container]}>
      <View style={[theme.flex.center, styles.header]}>
        <Text fontSize={18} fontFamily="fontInterBold">
          {i18n.t('bets-share.label.share')}
        </Text>
        <NativeTouchableOpacity style={styles.close} onPress={onClose}>
          <Image source={closeIcon} style={[styles.closeIcon]} />
        </NativeTouchableOpacity>
      </View>
      <ScrollView horizontal contentContainerStyle={[styles.listContainer]}>
        {(imIcon ? [imShare, ...thirdShares] : thirdShares).map(
          (item, index) => (
            <NativeTouchableOpacity
              onPress={() => {
                const user = localStorage.getItem('user') || '{}';
                const userId = JSON.parse(user).userId;
                onItemPress(item.label);
                postReport({
                  reportType: TReportType.CLICK,
                  userId,
                  sourceType: TSourceType.CHANNEL,
                  gameType,
                });
              }}
              style={styles.item}
              key={index}>
              {item.icon()}
              <Text style={[theme.margin.topxxs]}>{item.label}</Text>
              <Text
                color={theme.backgroundColor.orange}
                fontFamily="fontInterBold"
                size="medium">
                {index === 0 && hasReward ? '+ ' : ''}
                {index === 0 && hasReward
                  ? toPriceStr(1, {
                      fixed: 2,
                      showCurrency: true,
                      thousands: true,
                    })
                  : ''}
              </Text>
            </NativeTouchableOpacity>
          ),
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  header: {
    height: 58,
  },
  close: {
    top: 19,
    position: 'absolute',
    left: 16,
  },
  closeIcon: {
    width: 20,
    height: 20,
  },
  item: {
    alignItems: 'center',
    marginLeft: 24,
  },
  itemIcon: {
    width: 48,
    height: 48,
  },
  listContainer: {
    paddingVertical: 24,
    marginRight: 24,
  },
});

export default SharePanel;
