import theme from '@/style';
import {Modal, StyleSheet, View, ScrollView} from 'react-native';
import Text from '@/components/basic/text';
import {CloseIcon} from '@/common-pages/proxy/svg.variable';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React from 'react';
import LinearGradient from '@basicComponents/linear-gradient/linear-gradient';
import {useResponsiveDimensions} from '@/utils';

export interface RuleModalProps {
  visible: boolean;
  title?: string;
  children: React.ReactNode;
  scrollEnabled?: boolean;
  onClose: () => void;
}

const RuleModal = ({
  onClose,
  children,
  visible = false,
  title = '',
  scrollEnabled = true,
}: RuleModalProps) => {
  const {height} = useResponsiveDimensions();

  const Ele = scrollEnabled ? ScrollView : View;

  return (
    <Modal
      visible={visible}
      transparent
      statusBarTranslucent={false}
      animationType="fade">
      <View style={styles.container}>
        <View
          style={[
            theme.background.white,
            styles.innerContainer,
            scrollEnabled
              ? {
                  maxHeight: height * 0.75,
                }
              : {
                  height: height * 0.75,
                },
          ]}>
          <View
            style={[
              theme.flex.row,
              theme.flex.between,
              theme.flex.centerByCol,
              theme.padding.btml,
              // eslint-disable-next-line react-native/no-inline-styles
              {paddingHorizontal: 20},
            ]}>
            <Text size="large" fontFamily="fontInterBold">
              {title}
            </Text>
            <NativeTouchableOpacity
              onPress={() => {
                onClose && onClose();
              }}>
              <CloseIcon width={24} height={24} />
            </NativeTouchableOpacity>
          </View>
          <Ele
            style={[!scrollEnabled && theme.flex.flex1]}
            // eslint-disable-next-line react-native/no-inline-styles
            contentContainerStyle={{paddingHorizontal: 20, marginBottom: 20}}>
            {children}
          </Ele>
          <LinearGradient
            colors={['rgba(255, 255, 255, 0)', '#fff']}
            style={[styles.bottom]}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 8,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  innerContainer: {
    paddingTop: 20,
    overflow: 'hidden',
    borderRadius: 20,
  },
  bottom: {
    position: 'absolute',
    height: 120,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

export default RuleModal;
